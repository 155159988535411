
import { ref, defineComponent, onMounted } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { requiredField } from "@/util/form-rules";
import FormLoading from "@/components/layout/FormLoading.vue";
import Service from "@/services/admin/AvaliacaoService";
import CursoService from "@/services/admin/CursoService";
import TurmaService from "@/services/admin/TurmaService";
import DisciplinaService from "@/services/admin/DisciplinaService";
import TipoAvaliacaoService from "@/services/admin/TipoAvaliacaoService";
import TipoAvaliacao from "@/interfaces/TipoAvaliacao";
import Disciplina from "@/interfaces/Disciplina";
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import Divisao from "@/interfaces/Divisao";
import BaseService from "@/services/admin/BaseService";
import useForm from "@/modules/useForm";
import router from "@/router";
import store from "@/store";
import DivisaoService from "@/services/auth/DivisaoService";

interface Form {
  nome: string;
  descricao: string;
  imagem: string;
  url: string;
  url2: string;
  url3: string;
  nome_url: string;
  nome_url2: string;
  nome_url3: string;
  disciplina_id: string;
  disciplinas: string[];
  tipo_avaliacao_id: string;
  data_inicio: string;
  data_fim: string;
  divisao_id: string;
  curso_id: string;
  turma_id: string;
  avaliacao_id: string;
  nota_minima: number;
  nota_maxima: number;
  assunto: string;
}

const baseForm: Form = {
  nome: "",
  assunto: "",
  descricao: "",
  imagem: "",
  url: "",
  url2: "",
  url3: "",
  nome_url: "",
  nome_url2: "",
  nome_url3: "",
  disciplina_id: "",
  disciplinas: [],
  tipo_avaliacao_id: "",
  data_inicio: "",
  data_fim: "",
  divisao_id: "",
  curso_id: "",
  turma_id: "",
  avaliacao_id: "",
  nota_minima: 0,
  nota_maxima: 0,
};

export default defineComponent({
  components: { FormLoading },
  props: {
    avaliacoes: { default: [] },
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const form = ref<Form>(JSON.parse(JSON.stringify(baseForm)));
    const disciplinas = ref<Disciplina[]>([]);
    const cursos = ref<Curso[]>([]);
    const turmas = ref<Turma[]>([]);
    const tiposAvaliacao = ref<TipoAvaliacao[]>([]);
    const divisoes = ref<Divisao[]>([]);
    const hiddenDivisao = ref<boolean>(false);
    const { row, loading } = useForm(
      "avaliacoes",
      "avaliações",
      "a",
      baseForm,
      emit,
      "avaliacoes"
    );

    const buildFormData = (
      formData: any,
      data: any,
      parentKey: any = null
    ): FormData => {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
      return formData;
    };

    const submit = (): void => {
      if (!row.value?.id) {
        loading.value = true;
        let formData = new FormData();
        formData = buildFormData(formData, form.value);
        formData.append("imagem", form.value.imagem);
        Service.create(formData)
          .then(() => {
            showNotify({
              type: "positive",
              message: "Avaliação salva com sucesso",
            });

            router.push({ name: "area-avaliacao" });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao salvar avaliação",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getDisciplinasSelect(store.getters.divisaoId)
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("disciplinas")
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getCursos = (): void => {
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          });
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
      }
    };


    const getTurmas = (): void => {
      loading.value = true;
      TurmaService.list()
        .then((res) => {
          turmas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar turmas",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTiposAvaliacao = (): void => {
      loading.value = true;
      TipoAvaliacaoService.list()
        .then((res) => {
          tiposAvaliacao.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar tipos",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getDivisoes = (): void => {
      loading.value = true;
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
          if (store.getters.divisaoId) {
            hiddenDivisao.value = true;
            form.value.divisao_id = store.getters.divisaoId;
          }
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getInput = (): string | undefined => {
      return tiposAvaliacao.value.find(
        (ta) => ta.id == form.value?.tipo_avaliacao_id
      )?.nome;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getDisciplinas();
      getTiposAvaliacao();
      getCursos();
      getTurmas();
      getDivisoes();
    });

    return {
      open,
      form,
      row,
      requiredField,
      disciplinas,
      tiposAvaliacao,
      loading,
      showNotify,
      getCursos,
      getTurmas,
      getDisciplinas,
      getTiposAvaliacao,
      submit,
      getInput,
      cursos,
      turmas,
      divisoes,
      getDivisoes,
      hiddenDivisao,
    };
  },
});
